<template>
  <div class="text-center">
    <v-snackbar v-model="show" :timeout="timeout" :color="color">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{icon}}</v-icon>
        <v-layout column>
          <div>
            <strong>{{systemMessageTitle}}</strong>
          </div>
          <div>{{systemMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "systemMessage",
  computed: {
    ...mapGetters(["showSystemMessage", "systemMessage", "systemMessageTitle", "systemMessageType",]),
    color(){
      if( this.systemMessageType == 'serverError') return 'indigo'
      if( this.systemMessageType == 'appError') return 'purple'
      if( this.systemMessageType == 'networkError') return 'red darken-1'
      return 'error'
    },
    icon(){
      if( this.systemMessageType == 'serverError') return 'mdi-server-off'
      if( this.systemMessageType == 'appError') return 'mdi-cog-off'
      if( this.systemMessageType == 'networkError') return 'mdi-lan-disconnect'
      return 'mdi-alert'
    },
    show: {
      get() {
        return this.showSystemMessage;
      },
      set(value) {
        this.$store.dispatch("setShowSystemMessage", value);
      },
    },
  },
  data: () => ({
    timeout: 3000,
  }),
};
</script>

<style>
.system-message {
  margin: 0px !important;
}
</style>