<template>
  <v-app id="inspire">
    <v-container fluid fill-height style="background-color: #0d3863">
      <v-layout align-center justify-space-around row>
        <v-row justify="center">
          <v-col sm="6" xs="12" md="4">
            <v-card class="elevation-12">
              <v-card-title>Restaurar contraseña</v-card-title>

              <v-layout align-center justify-center>
                <v-row>
                  <v-col cols="12">
                    <v-form v-on:submit.prevent>
                      <v-card class="elevation-0">
                        <v-card-text>
                          <v-text-field
                            v-model="contrasena"
                            name="login"
                            type="password"
                            label="Constraseña nueva"
                            outlined
                            dense
                            :disabled="loading"
                            :error-messages="contrasenaErrors"
                            @input="validarContrasena()"
                            @blur="validarContrasena()"
                          ></v-text-field>
                          <v-text-field
                            v-model="confirmar"
                            name="login"
                            label="Confirmar Contraseña"
                            type="password"
                            outlined
                            dense
                            :disabled="loading"
                            :error-messages="confirmarErrors"
                            @input="validarConfirmar()"
                            @blur="validarConfirmar()"
                          ></v-text-field>
                          <vue-recaptcha
                            sitekey="6LdwLLYZAAAAADRkHh_ptXA6rtFW-4s2I2hTPzzV"
                            :loadRecaptchaScript="true"
                            ref="recaptcha"
                            @verify="verifyRecaptcha"
                            @expired="recaptchaToken = null"
                            @error="recaptchaToken = null"
                          />
                        </v-card-text>
                        <v-card-text v-if="mensaje">
                          {{ mensaje }}
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            type="submit"
                            color="primary"
                            dark
                            :loading="loading"
                            :class="{ 'disable-events': !formularioValido }"
                            block
                            @click="restablecerPassword()"
                            >Actualizar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-col>
                </v-row>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
      <systemMessageComponent />
    </v-container>
  </v-app>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";

import systemMessageComponent from "../globals/template/systemMessage.vue";

import { getRecaptchaToken, restablecerPasswordService } from "./login.service";

export default {
  name: "restaurarPassView",
  components: { VueRecaptcha, systemMessageComponent },
  mixins: [validationMixin],
  computed: {
    formularioValido() {
      if (this.contrasenaErrors.length > 0) return false;
      if (this.confirmarErrors.length > 0) return false;
      return true;
    },
  },
  validations: {
    contrasena: { required, minLength: minLength(5) },
    confirmar: { sameAs: sameAs("contrasena"), required },
  },
  data() {
    return {
      loading: false,
      token: null,
      contrasenaErrors: [],
      confirmarErrors: [],
      contrasena: "",
      confirmar: "",
      recaptchaToken: null,
      mensaje: null,
      redireccionando: 5,
    };
  },
  created() {
    this.token = this.$route.params.token;
  },
  methods: {
    verifyRecaptcha(response) {
      this.recaptchaToken = response;
    },
    async restablecerPassword() {
      this.error = null;
      try {
        this.validarContrasena();
        this.validarConfirmar();
        if (this.contrasenaErrors.length == 0) {
          const sendData = {
            token: this.token,
            recaptchaToken: this.recaptchaToken,
            password: this.contrasena,
          };
          this.loading = true;
          const serverResponse = await restablecerPasswordService(sendData);
          this.loading = false;
          this.mensaje = serverResponse.mensaje;
          if (serverResponse.ok) {
            this.loading = true;
            this.mensaje =
              "Contraseña actualizada correctamente, redireccionado al login...";
            setTimeout(() => this.$router.push("/login"), 5000);
          }
        }
      } catch (error) {
        this.loading = false;
        if (!error.handdled)
          this.$appErrorMessage("Ocurrió un error al realizar el registro.");
      }
    },
    validarContrasena() {
      this.contrasenaErrors = [];
      !this.$v.contrasena.required &&
        this.contrasenaErrors.push("Campo requerido.");
      !this.$v.contrasena.minLength &&
        this.contrasenaErrors.push("Mínimo 5 caracteres.");
    },
    validarConfirmar() {
      this.confirmarErrors = [];
      !this.$v.confirmar.required &&
        this.confirmarErrors.push("Campo requerido.");
      !this.$v.confirmar.sameAs &&
        this.confirmarErrors.push("Deben ser iguales");
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}

#logo {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.invalid_input {
  border: 1px solid red;
}
</style>
