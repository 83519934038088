import { getServerRequest, postServerRequest, putServerRequest, patchServerRequest } from '../globals/services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function validarCorreoRegistroService(data) {
    const url = `${httpURL}/registro/validarCorreo`;
    return await postServerRequest(url, data, {});
}

export async function registrarUsuarioService(data) {
    const url = `${httpURL}/registro/usuario`;
    return await putServerRequest(url, data, {});
}

export async function iniciarSesionService(data) {
    const url = `${httpURL}/login`;
    return await postServerRequest(url, data, {});
}

export const getRecaptchaToken = async() => {
    return new Promise((resolve, reject) => {
        grecaptcha.ready(function() {
            grecaptcha
                .execute("6Ld5pY8dAAAAAHb6OMg_8qCo-3RJJa0ALk_mrIXB", {
                    action: "submit",
                })
                .then(function(token) {
                    resolve(token);
                });
        });
    });
}

export async function activarCuentaService(idUsuario) {
    const url = `${httpURL}/login/activarCuenta`;
    return await postServerRequest(url, { idUsuario }, {});
}

export async function enviarCorreoActivacionService(correo) {
    const url = `${httpURL}/login/reenvioActivacion`;
    return await postServerRequest(url, { correo }, {});
}

export async function enviarCorreoRecuperacionService(data) {
    const url = `${httpURL}/login/correoRecuperacion`;
    return await postServerRequest(url, data, {});
}

export async function restablecerPasswordService(data) {
    const url = `${httpURL}/login/restablecerPass/token`;
    return await patchServerRequest(url, data, {});
}

export async function contenidoLegalService(clave) {
    const url = `${httpURL}/legales/${clave}`;
    return await getServerRequest(url, {});
}